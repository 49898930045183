.navigationbar {
  z-index: 999;
  height: $navbar-height;
  margin-top: calc(-#{$navbar-height} - 1rem);
  position: fixed;
  width: 100%;
  transition: background-color 500ms linear;
  &__user {
    @include media-breakpoint-up(sm) {
      order: 3; }
    border-left: 1px solid $navbar-dark-color; }
  &--sticky {
    background-color: $dark; } }
.navplaceholder {
  height: 70px;
  width: 100%;
  background-color: $dark;
  position: fixed;
  top: 0;
  z-index: 990; }
.navplaceholder:after {
  position: relative;
  height: 70px; }

.navbar-dark .navbar-nav .dropdown-menu.show .nav-link {
  color: $light; }

.navbar-nav .dropdown-menu .nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }
