.table-wrapper {

  thead {
    font-weight: bolder;
    font-size: 20px;
    color: #fff;
 }    // display: block
  .table-body {
    overflow-y: scroll;
 }    // display: block

  .bg-blue:hover {
    background-color: #3e64ff;
    color: #eee;
    cursor: pointer; }

  .table thead th,
  .table td {
    border: none; }

  .table tbody td:first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px; }

  .table tbody td:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px; }

  #spacing-row {
    height: 10px; } }
