$max-hero-height: 25vh;
.contract-detail-hero-image-container {
  width: 100%;
  max-height: $max-hero-height;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    object-fit: cover; } }
