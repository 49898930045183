body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

h4, h5, h6, .h4, .h5, .h6, p, .p, h3, .h3 {
  font-family: montserrat; }

h1, h2, .h1, .h2 {
  font-family: montserrat Bold; }

.bolder {
  font-weight: $font-weight-bolder; }

.bold {
  font-weight: $font-weight-bold; }

.highlight {
  color: $highlight-color; }

.tiny {
  font-size: 0.7em; }

// .btn-outline-primary, .btn-primary, .btn-secondary, .btn-outline-secondary
//   font-size: 13px
//   text-transform: uppercase
//   font-weight: bold
//   min-width: 85px
//   &:hover
//     box-shadow: 4px 4px 0px 0px $highlight-color
//     transform: translateY(-5px) translateX(-5px)
//     transition: ease-in-out 0.3s

// .btn-outline-primary
//   background: transparent !important
//   color: $black !important
//   border-color: $black
//   border-width: 2px
//   border-radius: 0
//   &:hover
//     background: transparent
//     border-color: $black
//     color: $black

// .btn-outline-secondary
//   border: 1px solid #999
//   color: black
//   &:hover
//     background: transparent
//     border-color: $highlight-color
//     color: $highlight-color


// .btn-primary
//   color: $white !important
//   background: $primary !important
//   border: 0


// .btn-secondary
//   color: $white
//   background: $black

// .remove-hover-outline, .remove-hover-black, .remove-hover-yellow
//   &:hover
//     box-shadow: none !important
//     transform: none!important
//     transition: none!important
//     cursor: pointer!important

// .remove-hover-outline
//   &:hover
//     color: black!important

// .remove-hover-black
//   &:hover
//     background: transparent!important
// .remove-hover-yellow
//   &:hover
//     background: $primary!important
