.sidebar {
  width: $dashbard-sidebar-width;
  position: fixed;
  top: $navbar-height;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  @include media-breakpoint-down(md) {
    top: $navbar-height;
    left: - $dashbard-sidebar-width;
    transition: left 0.25s ease-in-out;
    bottom: 0;
    &.active {
      left: 0; } }
  &__scrollcontainer {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    &::-webkit-scrollbar {
      width: 0; }
    &::-webkit-scrollbar-thumb {
      background-color: transparent; } }

  &--inactive {
    width: $dashbard-sidebar-width-collapsed; }

  .main-menu {
    .menu-item {
      .nav-link {
        padding: var(--bs-nav-link-padding-y) 2rem;
        color: white; }
      .nav-link:hover {
        color: $secondary; }
      .nav-link.active {
        background: transparent;
        border-radius: 5px;
        color: $secondary !important;
 }        // padding: 10px
      .menuwrapper {
        &--inactive {
          align-items: center;
          justify-content: center; } } } }

  &--inactive .main-menu .menu-item span {
    opacity: 0;
    width: 0;
    height: 0;
    margin-left: 0 !important; } }

.sidebar__sub-menu {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  transition: left 0.3s ease;
  &.active {
    left: 0; } }
