// font from CDN
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700")

///mixins
@import "styles/mixins/fontface";

//fonts
@include fontFace('rockwell', '/assets/fonts/rockwell');

@include fontFace('montserrat', '/assets/fonts/montserrat-v18-latin-500', 'normal', 500);
@include fontFace('montserrat', '/assets/fonts/montserrat-v18-latin-600', 'normal', 600);
@include fontFace('montserrat', '/assets/fonts/montserrat-v18-latin-700', 'normal', 700);
@include fontFace('montserrat', '/assets/fonts/montserrat-v18-latin-800', 'normal', 800);
@include fontFace('montserrat', '/assets/fonts/montserrat-v18-latin-900', 'normal', 900);
@include fontFace('montserrat', '/assets/fonts/montserrat-v18-latin-regular');
@include fontFace('montserrat SemiBold', '/assets/fonts/montserrat-SemiBold');
@include fontFace('montserrat Bold', '/assets/fonts/montserrat-Bold');
@include fontFace('montserrat Black', '/assets/fonts/montserrat-Black');

// theme
@import "../node_modules/bootstrap/scss/functions";
@import "styles/bootstrapvariables";
@import "styles/prebootstrapvariables";
@import "styles/variables";

// vendor modules
@import "../node_modules/bootstrap/scss/bootstrap";
@import "styles/bootstrapoverwrites";

// base
@import "styles/base/utilities";

// stylesheets layouts
@import "styles/layouts/CreatorLayoutRoute";
@import "../node_modules/@bkry/bowline-components/PageBuilder/PageBuilder";
@import "../node_modules/@bkry/bowline-components/Roadmap/Roadmap";

// stylesheets containers
@import "styles/containers/Root";
@import "styles/containers/App";
@import "styles/containers/Home";
@import "styles/containers/ContractDetail";
@import "styles/containers/Dashboard";
@import "styles/containers/Account";
@import "styles/containers/FourOFour";
@import "styles/containers/PageBuilder";

//stylesheets components
@import "components/About/About";
@import "components/FieldInput/FieldInput";
@import "components/FileInput/FileInput";
@import "components/LoadingContent/LoadingContent";
@import "components/Navbar/Navbar";
@import "components/Project/ProjectActivationModal/ProjectActivationModal";
@import "components/Register/Register";
@import "components/Sidebar/Sidebar";
@import "components/TableChart/TableChart";
@import "components/Token/TokenProperties/TokenProperty";
@import "components/Token/TokenRow/TokenRow";
@import "components/Project/ProjectActivationModal/utils/PricingPlans";

body {
  margin: 0;
  padding: 0;
  overflow: scroll !important;
  background: $light;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.btn-circle-float {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  i {
    transform: translateY(15%); } }

.cursor-pointer {
  cursor: pointer; }

table > :not(:first-child) {
  border-top: none !important; }

.table {
  tr {
    .hideableactionbuttons {
      button, a {
        opacity: 0; } }
    &:hover {
      .hideableactionbuttons {
        button, a {
          opacity: 1; } } } } }
