.pricingplans {
  .tabsBtnHolder {
    position: relative;
    ul {
      float: left;
      display: block;
      width: 100%;
      max-width: 290px;
      border-radius: 1.6666666667rem;
      margin: 0px auto;
      margin-bottom: 40px;
      background: $secondary;
      text-align: center;
      position: relative;
      list-style: outside none none;
      padding: 0;
      li {
        float: left;
        width: calc(100% / 2);
        display: inline-block;
        transition: 0.4s ease;
        p {
          color: #fff;
          padding: 10px 15px;
          z-index: 10;
          position: relative;
          margin: 0; }
        p.active {
          color: $secondary; } }
      li.indicator {
        position: absolute;
        top: 50%;
        left: 2px;
        background: #fff;
        height: calc(100% - 4px);
        transform: translateY(-50%);
        border-radius: 1.5333333333rem;
        width: 50%;
        z-index: 9;
        &__yearly {
          left: calc(50% - 2px); } } } } }
