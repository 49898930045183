.tokenrow__thumbnail {
  &__container {
    height: 2rem;
    width: 2rem;
    overflow: hidden;
    position: relative;
    background-color: $gray-300; }
  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover; } }
